.card {
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: capitalize;
}

/*Theme Colors*/
.theme-gray {
    background-color: #393E46;
    color: white;
}

.theme-gray .card {
    color: black;
}

.theme-blue {
    background-color: #00ADB5;
}

.theme-light-gray {
    background-color: #FFF4E0;
}

.theme-yellow {
    background-color: #F8B500;
}

.theme-red {
    background-color: #FC3C3C;
    color: white;
}

/*Text colora*/
.gray {
    color: #393E46
}

.bg-stripes {
    background: url(./img/stripes-light.png);
}

.navbar {
    z-index: 100;
}

.App .btn-primary {
    color: #00ADB5;
    background-color: #fff;
    border-color: #fff;
    font-weight: bold;
}

.App .btn-primary:hover,
.App .btn-primary:not(:disabled):not(.disabled).active,
.App .btn-primary:not(:disabled):not(.disabled):active,
.App .show>.btn-primary.dropdown-toggle {
    color: #00ADB5;
    background-color: #fff;
    border-color: #fff;
}

.App .btn-primary:not(:disabled):not(.disabled).active:focus,
.App .btn-primary:not(:disabled):not(.disabled):active:focus,
.App .show>.btn-primary.dropdown-toggle:focus,
.App .btn-primary.focus,
.App .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 173, 181, .5);
}

.App .btn-outline-primary {
    color: #fff;
    border-color: #fff;
    font-weight: bold;
}

.App .btn-outline-primary:hover,
.App .btn-outline-primary:not(:disabled):not(.disabled).active,
.App .btn-outline-primary:not(:disabled):not(.disabled):active,
.App .show>.btn-outline-primary.dropdown-toggle {
    color: #00ADB5;
    background-color: #fff;
    border-color: #fff;
}

.App .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.App .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.App .show>.btn-outline-primary.dropdown-toggle:focus,
.App .btn-outline-primary.focus,
.App .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 173, 181, .5);
}