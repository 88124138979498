@media (min-width: 768px) {

    .d-flex.align-items-stretch.col-sm-6,
    .d-flex.align-items-stretch.col-md-4 {
        padding-right: 0;
    }
}


.App .btn-project-grid {
    color: #00ADB5;
    background-color: #fff;
    border-color: #00ADB5;
}

.App .btn-project-grid:hover,
.App .btn-project-grid:not(:disabled):not(.disabled).active,
.App .btn-project-grid:not(:disabled):not(.disabled):active,
.App .show>.btn-project-grid.dropdown-toggle {

    color: #fff;
    background-color: #00ADB5;
    border-color: #00ADB5;
}