.App .filters {
    text-align: center;
    margin-bottom: 40px;
}

.App .filters button {
    margin-bottom: 10px;
    margin-right: 10px;
    text-transform: capitalize;
}

.App .filters .btn-outline-primary {
    color: #00ADB5;
    border-color: #00ADB5;
}

.App .filters .btn-outline-primary:hover,
.App .filters .btn-outline-primary:not(:disabled):not(.disabled).active,
.App .filters .btn-outline-primary:not(:disabled):not(.disabled):active,
.App .filters .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00ADB5;
    border-color: #fff;
}

.App .filters .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.App .filters .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.App .filters .show>.btn-outline-primary.dropdown-toggle:focus,
.App .filters .btn-outline-primary.focus,
.App .filters .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 173, 181, .5);
}