.navbar {
    z-index: 1000;
    background-color: #393E46;
}

nav a {
    color: #FFF;
}

/*button.navbar-toggler {
    border: 1px solid #fff;
}*/

/*.navbar-toggler-icon {
    background-color: #fff;
}*/

nav.bg-dark {
    background-color: #00ADB5 !important;
}

.navbar-dark .navbar-toggler {
    border-color: rgba(255, 255, 255, 0) !important;
}

.App .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .8);
}