body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: capitalize;
}

/*Theme Colors*/
.theme-gray {
    background-color: #393E46;
    color: white;
}

.theme-gray .card {
    color: black;
}

.theme-blue {
    background-color: #00ADB5;
}

.theme-light-gray {
    background-color: #FFF4E0;
}

.theme-yellow {
    background-color: #F8B500;
}

.theme-red {
    background-color: #FC3C3C;
    color: white;
}

/*Text colora*/
.gray {
    color: #393E46
}

.bg-stripes {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQBAMAAABykSv/AAAAFVBMVEX39/f29vb6+vr5+fn19fX4+Pj7+/vE9xmnAAAC70lEQVR4AdSUwa3CQAxE55eQjytIBHdQGjBsA5HSfy0Qc7G0RNbcZn3a1Zt3GCVr/E3HzDH3OP/HeZnOgKYBa595xeXaYh5x2dsJ2DSNKOI5dUmpX8A1DRhZfXdNA1akOuCaBoys3lzTgJHVm2sasOIddcA1DViR6oBrGjCyenNNA8b8i2FrGvg2XHJqTakeuKaBOWbLDS2leuCaBqhtfcxT00CRGgaAWw66AHR1UQBClwYoF9sgAOViGwSA+J7SAIQuDUDo0gDEtpYGWNnqN00D9NZYNI13O3dwAkAIQ1FQsAGrsDH770FIzrmKgbntEmxgeHxAFz+ADtABOkAH6AAdoAN0gA7QATpAB+gAHaADdICuOuw/X4zayHodAB2gA3SADtABOkAH6AAdoAN0gA7QATpAB+gAHaBT0D16AegAHaADdIAO0AE6QAfoAB2gA3SADtABOkAH6ABd7p/N+DnxvVK8mh1s0AE6QAfoAB2gA3SAri/QATpAB+gAHaADdIAO0AE6BZ2CTkEH6AAdoAN0gA7QATpAB+gAHaADdIAO0AE6QAfobNDZoAN0gA7QATpAB+gAHaADdIAO0AE6QAfoAB2gA3SATkGnoAN0gA7QATpAB+gAHaADdIAO0AE6QAfoAB2gA3Q26GzQATpAB+gAHaADdIAO0AE6QAfoAB2gA3SADtABOgWdgk5BB+gAHaADdIAO0AE6QAfoAB2gA3SADtABOkAH6GzQ2aADdIAO0AE6QAfoAB2gA3SADtABOkAH6AAdoAN0CjoFnYIO0AE6QAfoAB2gA3SADtABOkAH6AAdoAN0gA7Q2aCzQQfoAB2gA3SADtABOkAH6AAdoAN0gA7QATpAB+gUdAo6BR2gA3SADtABOkAH6AAdoAN0gA7QATpAB+gAHaCzQWeDDtABOkAH6AAdoAN0gA7QATpAB+gAHaADdIAO0CnoFHQKOkAH6AAdoAN0gA7QATpAB+gAHaADdIAO0AE6QGeD7gLgnopDNn7qMgAAAABJRU5ErkJggg==);
}

.navbar {
    z-index: 100;
}

.App .btn-primary {
    color: #00ADB5;
    background-color: #fff;
    border-color: #fff;
    font-weight: bold;
}

.App .btn-primary:hover,
.App .btn-primary:not(:disabled):not(.disabled).active,
.App .btn-primary:not(:disabled):not(.disabled):active,
.App .show>.btn-primary.dropdown-toggle {
    color: #00ADB5;
    background-color: #fff;
    border-color: #fff;
}

.App .btn-primary:not(:disabled):not(.disabled).active:focus,
.App .btn-primary:not(:disabled):not(.disabled):active:focus,
.App .show>.btn-primary.dropdown-toggle:focus,
.App .btn-primary.focus,
.App .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 173, 181, .5);
}

.App .btn-outline-primary {
    color: #fff;
    border-color: #fff;
    font-weight: bold;
}

.App .btn-outline-primary:hover,
.App .btn-outline-primary:not(:disabled):not(.disabled).active,
.App .btn-outline-primary:not(:disabled):not(.disabled):active,
.App .show>.btn-outline-primary.dropdown-toggle {
    color: #00ADB5;
    background-color: #fff;
    border-color: #fff;
}

.App .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.App .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.App .show>.btn-outline-primary.dropdown-toggle:focus,
.App .btn-outline-primary.focus,
.App .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 173, 181, .5);
}
.navbar {
    z-index: 1000;
    background-color: #393E46;
}

nav a {
    color: #FFF;
}

/*button.navbar-toggler {
    border: 1px solid #fff;
}*/

/*.navbar-toggler-icon {
    background-color: #fff;
}*/

nav.bg-dark {
    background-color: #00ADB5 !important;
}

.navbar-dark .navbar-toggler {
    border-color: rgba(255, 255, 255, 0) !important;
}

.App .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .8);
}
.footer p {
  font-size: 12px;
}

.section {
    padding-top: 150px;
    padding-bottom: 150px;
}

/*
ANGLES FOR SECTIONS
.section {
  margin-top: -20px;
  -webkit-clip-path: polygon(0 0,100% 6vw,100% 100%,0 calc(100% - 6vw));
  clip-path: polygon(0 0,100% 6vw,100% 100%,0 calc(100% - 6vw));
}*/
@media screen (min-width: 375px) {
    .section {
        margin-top: -23px;
    }
}

.section-separator {
    padding-top: 20px;
    padding-bottom: 20px;
}

.App .filters {
    text-align: center;
    margin-bottom: 40px;
}

.App .filters button {
    margin-bottom: 10px;
    margin-right: 10px;
    text-transform: capitalize;
}

.App .filters .btn-outline-primary {
    color: #00ADB5;
    border-color: #00ADB5;
}

.App .filters .btn-outline-primary:hover,
.App .filters .btn-outline-primary:not(:disabled):not(.disabled).active,
.App .filters .btn-outline-primary:not(:disabled):not(.disabled):active,
.App .filters .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #00ADB5;
    border-color: #fff;
}

.App .filters .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.App .filters .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.App .filters .show>.btn-outline-primary.dropdown-toggle:focus,
.App .filters .btn-outline-primary.focus,
.App .filters .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 173, 181, .5);
}
@media (min-width: 768px) {

    .d-flex.align-items-stretch.col-sm-6,
    .d-flex.align-items-stretch.col-md-4 {
        padding-right: 0;
    }
}


.App .btn-project-grid {
    color: #00ADB5;
    background-color: #fff;
    border-color: #00ADB5;
}

.App .btn-project-grid:hover,
.App .btn-project-grid:not(:disabled):not(.disabled).active,
.App .btn-project-grid:not(:disabled):not(.disabled):active,
.App .show>.btn-project-grid.dropdown-toggle {

    color: #fff;
    background-color: #00ADB5;
    border-color: #00ADB5;
}
.tag {
  text-transform: capitalize;
  font-size: 0.8rem;
  display: inline-block;
}

.more-work .projects-filtered .card {
    color: black;
}
.App .Home {
    background-image: url(/static/media/home-hero.27b0f0e9.jpg);
}

.scroll-x {
  overflow-x: auto;
  white-space: nowrap;
}
h1.section-index {
	font-size: 5rem;
}

