.section {
    padding-top: 150px;
    padding-bottom: 150px;
}

/*
ANGLES FOR SECTIONS
.section {
  margin-top: -20px;
  -webkit-clip-path: polygon(0 0,100% 6vw,100% 100%,0 calc(100% - 6vw));
  clip-path: polygon(0 0,100% 6vw,100% 100%,0 calc(100% - 6vw));
}*/
@media screen (min-width: 375px) {
    .section {
        margin-top: -23px;
    }
}

.section-separator {
    padding-top: 20px;
    padding-bottom: 20px;
}